<template>
  <div class="wameed-dashboard-page-content mb-5">
    <div>
      <page-header
        v-if="getCompanyDetails"
        :title="
          getCompanyDetails.status == 2
            ? $t('users_details.new_account_title')
            : $t('users_details.employee_seekers_title')
        "
        :sub-title="
          getCompanyDetails.status == 2
            ? $t('users_details.new_account_sub_title')
            : $t('users_details.employee_seekers_sub_title')
        "
      />
    </div>
    <section class="wameed-dashboard-page-content_body mt-3">
      <div v-if="getCompanyDetails" class="mx-4">
        <b-row class="">
          <b-col
            :md="
              getCompanyDetails.status != 0 &&
              getCompanyDetails.status != 4 &&
              getCompanyDetails.status != 1 &&
              getCompanyDetails.status != 6
                ? '10'
                : '7'
            "
            class="my-4"
          >
            <user-details-table
              v-if="getCompanyDetails"
              :data-employee="getCompanyDetails"
            />
          </b-col>
          <b-col
            v-if="
              getCompanyDetails.status == 0 ||
              getCompanyDetails.status == 4 ||
              getCompanyDetails.status == 1 ||
              getCompanyDetails.status == 6
            "
            md="5"
            class="my-4"
          >
            <contract-files-table
              :contract-before-signing="
                getCompanyDetails.contract_before_signing
              "
              :contract-file="getCompanyDetails.contract_file"
              :status="getCompanyDetails.status"
            />
          </b-col>
        </b-row>
      </div>
    </section>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import UserDetailsTable from '@/components/pages/users/UserDetailsTable.vue';
import ContractFilesTable from '@/components/pages/users/ContractFilesTable.vue';
import PageHeader from '@/components/PageHeader.vue';
import WameedBtn from '@/components/WameedBtn.vue';

export default {
  components: {
    UserDetailsTable,
    ContractFilesTable,
    PageHeader,
    WameedBtn,
  },
  data() {
    return {};
  },
  computed: {
    ...mapGetters({
      getCompanyDetails: 'getCompanyDetails',
    }),
  },
  mounted() {
    this.loadCompaniesDetails(this.$router.currentRoute.params.id);
  },

  methods: {
    ...mapActions({
      loadCompaniesDetails: 'loadCompaniesDetails',
      loadFile: 'loadFile',
    }),
    formatedDate(date) {
      if (date) {
        this.moment.locale(this.$i18n.locale === 'ar' ? 'ar_SA' : 'en_US');
        return this.moment().diff(this.moment(date), 'days') > 7
          ? this.moment(date).format('DD/MM/YYYY')
          : this.moment(date).fromNow(true);
      }
      return '------';
    },
  },
};
</script>
