<template>
  <div class="package-details-table bg-white overflow-hidden rounded-14">
    <b-row class="">
      <b-col sm="12" class="package-details-name">
        <h1 class="package-details-table__title text-font-main text-bold-16">
          {{ $t('users_details.contract_files') }}
        </h1>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.contract_before_signing') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <div
          v-if="contractBeforeSigning && contractBeforeSigning.file_name"
          class="d-flex flex-wrap comment-info--images mt-0"
          dir="rtl"
        >
          <label
            class="open-file-icon"
            @click="openContractFile(contractBeforeSigning)"
          >
            <span v-if="contractBeforeSigning.type == 'pdf'">
              <pdf-icon
            /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  contractBeforeSigning.name
                    ? contractBeforeSigning.name.split('_')[0]
                    : contractBeforeSigning.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ contractBeforeSigning.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
      </b-col>
    </b-row>
    <b-row class="package-details-table__body">
      <b-col sm="4" class="package-details-name">
        <p class="mb-0">{{ $t('orders_page.contract_file') }}:</p>
      </b-col>
      <b-col sm="8" class="package-details-value">
        <div
          v-if="contractFile && contractFile.file_name"
          class="d-flex flex-wrap comment-info--images mt-0"
          dir="rtl"
        >
          <label class="open-file-icon" @click="openContractFile(contractFile)">
            <span v-if="contractFile.type == 'pdf'"> <pdf-icon /></span>
            <span v-else> <word-icon /></span>
            <div class="file-data">
              <p class="text-bold-12 text-font-main m-0">
                {{
                  contractFile.name
                    ? contractFile.name.split('_')[0]
                    : contractFile.name
                }}
              </p>
              <p class="text-bold-10 text-font-secondary m-0" dir="ltr">
                {{ contractFile.size }} MB
              </p>
            </div>
            <!-- <open-file-icon /> -->
          </label>
        </div>
        <p v-else class="mb-0">
          {{ $t('orders_page.waiting_to_send_it') }}
        </p>
      </b-col>
    </b-row>
    <hr v-if="status == 4 || status == 6" />
    <b-row class="">
      <b-col sm="12" class="package-details-name py-5 d-flex p-0-30">
        <wameed-btn
          v-if="status == 4 || status == 6"
          classes="text-med-14 text-white rounded-10 mx-md-5 mx-0 w-100"
          :title="$t('orders_page.open_location_services')"
          type="button"
          :disabled="status == 4"
          variant="main"
          @submitAction="showChangeCompaniesStatus = true"
        />
      </b-col>
      <warning-modal
        :variant="'main'"
        :variant2="'success'"
        :icon-name="'confirmation-icon'"
        :visible="showChangeCompaniesStatus"
        :title="$t('orders_page.activation_open_location_services')"
        :sub-title="$t('common.activation_user_subtitle')"
        :btn-title="$t('common.open')"
        @close="showChangeCompaniesStatus = false"
        @submitAction="activateUser"
      />
    </b-row>
  </div>
</template>

<script>
import WarningModal from '@/components/WarningModal.vue';
import { mapActions, mapGetters } from 'vuex';
import { ValidationObserver } from 'vee-validate';
import WameedForm from '@/components/WameedForm.vue';
import WameedBtn from '@/components/WameedBtn.vue';
import WameedDropdown from '@/components/WameedDropdown.vue';
import TextInput from '@/components/TextInput.vue';
import TextArea from '../../TextArea.vue';

export default {
  components: {
    WameedBtn,
    WarningModal,
    ValidationObserver,
    TextArea,
    WameedForm,
    WameedDropdown,
    TextInput,
  },
  props: {
    contractBeforeSigning: {
      type: Object,
      default: null,
    },
    contractFile: {
      type: Object,
      default: null,
    },
    status: {
      type: Number,
      default: null,
    },
    badge: {
      type: Object,
      default() {
        return {
          name: 'مقبول',
          color: 'success',
        };
      },
    },
  },
  data() {
    return {
      showChangeCompaniesStatus: false,
    };
  },
  methods: {
    ...mapActions({
      changeCompaniesStatus: 'changeCompaniesStatus',
      uploadFiles: 'uploadFiles',
    }),
    openContractFile(data) {
      window.open(data.full_path + data.file_name, '_blank');
    },
    activateUser() {
      this.changeCompaniesStatus({
        id: this.$router.currentRoute.params.id,
        status: 1,
      }).then(() => {
        this.showChangeCompaniesStatus = false;
      });
    },
  },
};
</script>
