var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"wameed-dashboard-page-content mb-5"},[_c('div',[(_vm.getCompanyDetails)?_c('page-header',{attrs:{"title":_vm.getCompanyDetails.status == 2
          ? _vm.$t('users_details.new_account_title')
          : _vm.$t('users_details.employee_seekers_title'),"sub-title":_vm.getCompanyDetails.status == 2
          ? _vm.$t('users_details.new_account_sub_title')
          : _vm.$t('users_details.employee_seekers_sub_title')}}):_vm._e()],1),_c('section',{staticClass:"wameed-dashboard-page-content_body mt-3"},[(_vm.getCompanyDetails)?_c('div',{staticClass:"mx-4"},[_c('b-row',{},[_c('b-col',{staticClass:"my-4",attrs:{"md":_vm.getCompanyDetails.status != 0 &&
            _vm.getCompanyDetails.status != 4 &&
            _vm.getCompanyDetails.status != 1 &&
            _vm.getCompanyDetails.status != 6
              ? '10'
              : '7'}},[(_vm.getCompanyDetails)?_c('user-details-table',{attrs:{"data-employee":_vm.getCompanyDetails}}):_vm._e()],1),(
            _vm.getCompanyDetails.status == 0 ||
            _vm.getCompanyDetails.status == 4 ||
            _vm.getCompanyDetails.status == 1 ||
            _vm.getCompanyDetails.status == 6
          )?_c('b-col',{staticClass:"my-4",attrs:{"md":"5"}},[_c('contract-files-table',{attrs:{"contract-before-signing":_vm.getCompanyDetails.contract_before_signing,"contract-file":_vm.getCompanyDetails.contract_file,"status":_vm.getCompanyDetails.status}})],1):_vm._e()],1)],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }